<template>
  <div>
    <component :is="hdtemplate" :hd="hd" :config="config" :gList="gList" :goodList="goodList" v-if="hdtemplate" @onPoint="onPoint" ref="theme"/>
  
    <van-popup v-model="prizeModal" position="bottom" class="prizeBox" round :close-on-click-overlay="false">
      <div class="failBox" v-if="prize.title == '谢谢参与'">
        <van-row type="flex" justify="center">
          <van-col span="14"><img src="./image/fail.png"/></van-col>
          <van-col span="24" class="text">很遗憾，您未中奖！！继续努力！！</van-col>
          <van-col span="12"><van-button color="#cccccc" size="small" block round @click="()=>{
            this.initial()
          }">继续抽</van-button></van-col>
        </van-row>
      </div>
      <div class="successBox" v-else>
        <van-form @submit="onSubmitReceive">
        <div class="gimg"><img :src="prize.titleimg"/></div>
        <div class="gtitle">{{prize.title}}</div>
          <div class="money" v-if="prize.now_price>0">您已抽中{{prize.now_price}}元购买资格，请在30分钟内尽快完成支付!!</div>
          
          <van-cell-group class="contact">
            <van-field v-model="json.name" label="姓名" placeholder="请输入姓名" input-align="right" :rules="[{ required: true,message: '请输入姓名'}]" :error="false" error-message-align="right"/>
            <van-field v-model="json.mobile" label="手机" placeholder="请输入联系方式" input-align="right" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]" :error="false" error-message-align="right"/>
          </van-cell-group>
          <div class="tips" v-if="prize.stock>0">提交后在领取期内到店领(否则视为放弃)</div>
          <div class="tips" v-else>库存不足，立即<a @click="()=>{this.shopModal = true}" class="font-color-blue">联系商家</a>补货</div>
        <van-row type="flex" justify="center" class="action" :gutter="15">
          <van-col span="12" v-if="prize.stock>0">
            <van-button type="primary" size="small" color="linear-gradient(to right, #ff6034, #ee0a24)" block round native-type="submit">{{prize.now_price>0?'立即支付'+prize.now_price+'领取':'立即领取'}}</van-button>
          </van-col>
          <van-col span="12"><van-button color="#cccccc" size="small" block round @click="cancel(json.orderId)" native-type="button">放弃，继续抽</van-button></van-col>
        </van-row>
        </van-form>
      </div>

    </van-popup>
  
    <van-popup v-model="shopModal" :style="{width: '88%' }" closeable>
      <shopInfo :data="hd.shopInfo"/>
    </van-popup>
  </div>
</template>

<script>
  import egg from "@/assets/music/egg.mp3"
  import yao from "@/assets/music/yao.wav"
  import shopInfo from '../components/shopInfo'
  import {OrderCancel} from "@/pages/wx/services/services";
  import {GetCjConfig,GetCj,Receive} from "./services/services";
  import {mapGetters} from "vuex";
export default {
  name:'cj',
  props: {
    hd:Object,
    gList:Array,
    goodList:Array
  },
  components: {shopInfo},
  data() {
    return {
      hdtemplate:null,
      config:{
        flag:true,
        maxLen:0,
        during_time:3,
        angle:0,
        key:undefined
      },
      prize: {},
      prizeModal:false,
      json:{
        orderId:0,
        name:'',
        mobile:''
      },
      loading:false,
      timer:null,
      shopModal:false
    }
  },
  created(){
  
  },
  computed:{
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.hdtemplate = () => import(`./components/${this.hd.template}.vue`)
    if(this.userInfo != null){
      this.findConfig()
    }
  },
  methods: {
    initial(){
      this.json={
        orderId:0,
        name:'',
        mobile:''
      }
      this.prize={}
      this.prizeModal=false
      this.config={
        ...this.config,
        flag:true,
        maxLen:0,
        during_time:3,
        //angle:0,
        key:undefined
      }
    },
    validatorPhone(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    findConfig(){
      GetCjConfig({hdid:this.hd.id}).then(result => {
        if(result.data.code==1){
          this.config={
            ...this.config,
            ...result.data.data
          }
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    onPoint(option){
      if(!this.config.flag){
        return false
      }
      if(this.hd.state==0){
        this.$toast('活动即将开始')
        return false
      }else if(this.hd.state==2){
        this.$toast('活动已结束')
        return false
      }
      if(this.config.zj_num<=0){
        this.$toast('您的中奖次数已达上限')
        return false
      }
      if(this.config.cj_num<=0){
        this.$toast('您的抽奖次已用完')
        return false
      }
      if(this.config.flag==true && this.config.cj_num>0 && this.config.zj_num>0){
        this.config.flag=false
        this.action(option)
        GetCj({hdid:this.hd.id}).then(result => {
          if(result.data.code==1){
            const {pid,orderId}= result.data.data
            this.json.orderId=orderId
            this.prize = this.gList.find(item => item.id == pid)
            this.config.cj_num = this.config.cj_num-1
          }else{
            const {code} = result.data
            if(code==2){
              this.config.cj_num=0
            }else if(code==3){
              this.config.zj_num=0
            }
            this.$toast(result.data.msg)
            clearTimeout(this.timer)
          }
        })
      }
    },
    action({type}){
      //type 1大转盘 2砸蛋 3盲盒 4九宫格 5翻牌 6摇钱树
      switch (type) {
        case 1:
          this.rotate()
          break;
        case 2:
          this.smasheggs()
          break;
        case 3:
          this.box()
          break;
        case 4:
          this.move()
          break;
        case 5:
          this.card()
          break;
        case 6:
          this.cashcow()
          break;
      }
    },
    rotate(){
      let angle=360/this.gList.length
      if(this.prize.id){
        let key = this.gList.findIndex(item => item.id == this.prize.id)
        this.config.angle =  this.config.angle + 1 * 360 + (360-key*angle) - this.config.angle % 360
        clearTimeout(this.timer)
        setTimeout(() => {
          this.prizeModal=true
        }, 3000);
        return
      }
      let key=1
      this.config.angle =  this.config.angle + 2 * 360 + (360-key*angle) - this.config.angle % 360
      this.timer = setTimeout(() => {
        this.rotate()
      }, 200);
    },
    smasheggs(){
      var audio = new Audio();
      audio.src = egg;
      audio.play();
      setTimeout(() => {
        this.prizeModal=true
      }, 1000);
    },
    box(){
      if(this.prize.id) {
        let key = this.gList.findIndex(item => item.id == this.prize.id)
        this.config.key = key
        clearTimeout(this.timer)
        this.prizeModal=true
        return
      }
      this.timer = setTimeout(() => {
        this.box()
      }, 200);
    },
    move(){
      let speed=100
      let circle = 0
      this.timer = setInterval(() => {
        this.config.key++;
        if (this.config.key > this.gList.length-1) {
          circle++
          this.config.key = 0
        }
        if(this.prize.id && circle>1) {
          let key = this.gList.findIndex(item => item.id == this.prize.id)
          if(this.config.key == key){
            clearTimeout(this.timer)
            setTimeout(() => {
              this.prizeModal=true
            }, 1000);
            return
          }
        }
      },speed);
    },
    card(){
      if(this.prize.id) {
        let key = this.gList.findIndex(item => item.id == this.prize.id)
        this.config.key = key
        clearTimeout(this.timer)
        setTimeout(() => {
          this.prizeModal=true
        }, 1000);
        return
      }
      this.timer = setTimeout(() => {
        this.card()
      }, 200);
    },
    cashcow(){
      var audio = new Audio();
      audio.src = yao;
      audio.play();
      setTimeout(() => {
        this.prizeModal=true
      }, 1000);
    },
    onSubmitReceive(){
      this.loading=true
      Receive(this.json).then(result => {
        this.loading=true
        if(result.data.code==1){
          this.config.zj_num = this.config.zj_num-1
          const {orderId} = result.data.data
          if(this.prize.now_price>0){
            if(parseInt(process.env.VUE_APP_PRODUCTION)){
              location.href = process.env.VUE_APP_PAY_URL+'/wx/order/detail/'+orderId
            }else{
              this.$router.push({path:'/wx/order/detail/'+orderId})
            }
          }else{
            this.$toast.success('领取成功')
          }
          
        }else{
          this.$toast(result.data.msg)
        }
        this.initial()
      })
    },
    cancel(orderId){
      this.$dialog.confirm({
        title: '确认放弃吗？',
        theme: 'round-button',
      }).then(() => {
        OrderCancel({orderId:orderId}).then(result => {
          if(result.data.code==1){
            this.initial()
          }
        })
      })
    }
  }
}
</script>
<style lang="less">
  .prizeBox{overflow-y:visible;background:#f1f1f1;
    .successBox{position:relative;padding:50px 10px 10px 10px;text-align:center;
      &:after{content:'';width:60%;height:120px;position:absolute;left:20%;top:-80px;z-index:1;background:url("./image/success.png") no-repeat bottom center;background-size:100% auto;}
      .gimg{
        img{width:120px;border-radius:4px;}
      }
      .gtitle{font-size:18px;font-weight:700;margin-top:10px}
      .money{color:#ff0000;margin-top:10px;}
      .tips{color:#ff0000;height:30px;line-height:30px;text-align:left;}
      .contact{margin:10px 0px;}
      .action{padding:10px 25px;}
    }
    .failBox{text-align:center;padding:20px 0px;
      img{width:80%;}
      .text{font-size:16px;padding:20px 0px;color:#888;}
    }
    .nostockBox{}
  }
</style>

