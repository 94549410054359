import {
  cjconfig,cj,receive
} from './api'
import {request, METHOD} from '@/utils/request'
export async function GetCjConfig(params) {
  return request(cjconfig, METHOD.POST,params)
}
export async function GetCj(params) {
  return request(cj, METHOD.POST,params)
}

export async function Receive(params) {
  return request(receive, METHOD.POST,params)
}
